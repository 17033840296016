import React, {  Component, Fragment } from 'react';
import {Redirect} from 'react-router-dom';
import Waiting from './Waiting';
import moment from 'moment'
import Graph from './Graph';
import GraphDaily from './GraphDaily'
import GraphWeekly from './GraphWeekly';

class Game extends Component {

    state = {
        redirect:"",
        loaded:false,
        data: null
    }
    goHome = () => {
        const url = `/`;
        this.setState({redirect:url})        
    }

    componentDidMount() {
        const {id} = this.props.match.params
        let vUrl = "https://steam.kletellier.xyz/api/stats/" + id;
        fetch(vUrl)
       .then(res => res.json())
       .then(
         (result) => {
           this.setState({
             loaded: true,
             data: result
           });
         },       
         (error) => {
           this.setState({
             loaded: true,
             error
           });
         }
       )
     }

    render() {
        if(this.state.redirect)
        {
            return <Redirect to={this.state.redirect}></Redirect>
        }
        if(this.state.loaded)
        {
            const game = this.state.data.game;
            const gameinfo = (game) => {
            return (
                <div key={game.steam_id} className="row">
                <div className="col-sm-12"><h3>{ game.name }</h3>&nbsp;<img src={ game.logo } className="logo rounded"></img></div>
                 
                </div>
                )
            }  

            const {min,max} =  this.state.data;

            const minmax = (min,max) => {
                const mindate = moment(min.date,'YYYYMMDDHHmmss').format('DD/MM/YYYY à HH:mm');
                const maxdate = moment(max.date,'YYYYMMDDHHmmss').format('DD/MM/YYYY à HH:mm');

                return (
                <table>
                        <tr key="min">
                         <td>Nombre minimal <strong>{ min.count }</strong> le { mindate }</td>
                         </tr>
                         <tr key="max">
                            <td>Nombre maximal <strong>{ max.count }</strong> le { maxdate }</td>
                         </tr>
                        </table>
                )
            };

            const stats = this.state.data.stats; 
            let nb = (stats.length > 100) ? 100 : stats.length;
            const statistics = [...this.state.data.stats].reverse().slice(0,nb).map( (elem) => {
                const date = moment(elem.date,'YYYYMMDDHHmmss').format('DD/MM/YYYY HH:mm');
                const nb = elem.count;
                return (
                    <tr key={date}>
                         <td>{ date }</td>
                         <td>{ nb }</td>                             
                    </tr>
                )
            })

            const actual = [...this.state.data.stats].pop().count;

            return (
                <div className="container-fluid">
                    <div className="bmb row">
                    <div className="col-sm-4">
                    <a className="btn btn-primary" onClick={ () => this.goHome() }>Revenir à la liste</a>
                        </div>
                        
                    </div>                   
                    { gameinfo(game) } 
                    <div className="bmb row">
                        <div className="col-sm-4">
                        Actuellement : <h2>{ actual }</h2> joueurs
                        </div>                         
                    </div>
                    <div className="bmb row">
                        <div class="col">Sur 24 h</div>
                        <div class="col-11"><Graph largeur={600} hauteur={300} steam_id={game.steam_id}></Graph></div>
                        
                    </div>
                    <div className="bmb row">
                        <div class="col">Moyenne journalière</div>
                        <div class="col-11"><GraphDaily largeur={600} hauteur={300} steam_id={game.steam_id}></GraphDaily></div>
                    </div>
                    <div className="bmb row">
                        <div class="col">Moyenne hebdomadaire</div>
                        <div class="col-11"><GraphWeekly largeur={600} hauteur={300} steam_id={game.steam_id}></GraphWeekly></div>
                    </div>
                    <div className="bmb row">
                        <div class="col-12">{ minmax(min,max) }</div>
                    </div>
                    <div className="row">
                        <div className="col-sm-4">
                            <table className="table table-condensed table-bordered table-striped">
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Nombre</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    { statistics }
                                </tbody>
                            </table>
                        </div>
                        
                    </div>
                    </div>
            );
        }
        else
        {
           return (<Waiting></Waiting>);
        }
       
    }
}

export default Game;