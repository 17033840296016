import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.css';  
import './index.css';
import '@fortawesome/fontawesome-free/css/all.css'
import Liste from './components/Liste';
import Game from './components/Game' ;
import NotFound from './components/NotFound'  ;
import { BrowserRouter,Switch,Route } from 'react-router-dom';

const Root = () => (
  <BrowserRouter>
    <Switch>
      <Route exact path="/" component={Liste}></Route> 
      <Route path='/stats/:id' component={Game}></Route> 
      <Route component={NotFound}></Route>
    </Switch>
  </BrowserRouter>
)

ReactDOM.render(
  <React.StrictMode>
    <Root className="container" />
  </React.StrictMode>,
  document.getElementById('root')
);
 
