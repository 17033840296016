import React, { Component } from 'react';
import {BarChart,XAxis,YAxis,CartesianGrid,Tooltip,Bar,Legend} from 'recharts';
import Waiting from './Waiting';
import moment from 'moment';

class GraphWeekly extends Component {
    
    state = { 
        loaded:false,
        data: null,
        error:'',
    }    
    
    componentDidMount() {
        const {steam_id} = this.props
        let vUrl = "https://steam.kletellier.xyz/api/weekly/" + steam_id;
        fetch(vUrl)
        .then(res => res.json())
        .then(
            (result) => {
                this.setState({
                    loaded: true,
                    data: result
                });
            },       
            (error) => {
                this.setState({
                    loaded: true,
                    error
                });
                console.log(error);
            }
            )
        }
        
        render() {        
            if(!this.state.loaded || this.state.data==null)
            {
                return (<Waiting taille="fa-2x"></Waiting>)
            }
            else
            { 
                const stats = this.state.data.stats;  
                if(stats.length==0)
                {
                    return (<Waiting taille="fa-2x"></Waiting>)
                }
                else
                {
                    let nb = (stats.length > 52) ? 52 : stats.length; 
                    if(nb<1){nb=1;}
                    const statistics = [...this.state.data.stats].reverse().slice(0,nb).reverse();
                    
                    const stat2 = statistics.map( (elem) => {
                        let date =  moment(elem.jour,'YYYYWW').format('WW-YYYY');
                        let count = elem.count;
                        
                        return {
                            date:elem.jour,
                            datem:date,
                            count:parseInt(count)
                        }
                        
                    }); 
                     

                    let {largeur,hauteur} = this.props 
                    if(largeur===undefined){largeur=300;}
                    if(hauteur===undefined){hauteur=200;} 
                    
                    return (
                        <div className="col-12">
                        <BarChart width={largeur} height={hauteur} data={stat2}>
                            <CartesianGrid  stroke="#eee" strokeDasharray="5 5"/>
                            <XAxis dataKey="datem"/>
                            <YAxis type="number"/>
                            <Tooltip/>
                            <Legend />
                            <Bar dataKey="count" fill="#8884d8" /> 
                            </BarChart>    

                      
                        </div>
                        );
                        
                    }
                }               
                
            }        
        }   
        
        export default GraphWeekly;