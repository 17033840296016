import React, { Component } from 'react';
import Waiting from './Waiting'

class ActualCount extends Component {
    state = {
        id:"",
        loaded:false,
        count:"NC",
        refresh:true
    } 

    componentDidMount = () => {
        let timerId = setInterval(this.handleClickCount,60000);
        const new_state = this.state;
        new_state.id = this.props.steam_id;
        this.setState({new_state});
    }

    componentDidUpdate = () => {
        if(this.state.refresh)
        {
            this.updateCount();
        }
        
    }

    handleClickCount = () => {
        let newstate = this.state;
        newstate.refresh = true;
        this.setState({newstate})
    }

    updateCount = () => {
         
        let vUrl = "https://steam.kletellier.xyz/api/actual/" + this.state.id;
        fetch(vUrl)
       .then(res => res.json())
       .then(
         (result) => {
            
           this.setState({
             loaded: true,
             refresh: false,
             count: result.stats.count
           });
         },       
         (error) => {
           this.setState({
             refresh:false,
             loaded: true,
             error
           });
         }
       )
    }

    render() {
        if(!this.state.loaded)
        {
            return (<Waiting taille='fa-2x'></Waiting>)
        }
        else
        {
            return (
                <div onClick={ () => this.handleClickCount() } className="col-1">
                    <strong>{this.state.count}</strong>
                </div>
            );
        }
        
    }
}

export default ActualCount;