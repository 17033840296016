import React, {  Component } from 'react';
import {Redirect} from 'react-router-dom';
import Waiting from './Waiting';
import Graph from './Graph';
import ActualCount from './ActualCount';
class Liste extends Component {

    state = {
        loaded : false,
        liste:null,
        error:null,
        redirect:''
    }

    componentDidMount() {
       fetch("https://steam.kletellier.xyz/api/games")
      .then(res => res.json())
      .then(
        (result) => { 
          this.setState({
            loaded: true,
            liste: result
          });
        },       
        (error) => {
          this.setState({
            loaded: true,
            error
          });
        }
      )
    }

    goStats = (id) => {
        const url = `/stats/${id}`
        this.setState({redirect:url})
    }

    render() {
        if(this.state.redirect)
        {
            return <Redirect to={this.state.redirect}></Redirect>
        }
      if(this.state.loaded===false)
      {
        return (<Waiting taille="fa-3x"></Waiting>)
      }
      else
      {
        const liste = this.state.liste;
        const elements = liste.map((game) => {
            
              return (
                <div key={game.steam_id} className="gamelist row">
                    <div className="col-sm-3">{ game.name }</div>
                    <div className="col-sm-3"><img src={ game.logo } alt={game.name} className="logo rounded"></img></div>
                    <Graph largeur={100} hauteur={50} minimal={true} steam_id={game.steam_id}></Graph>     
                    <ActualCount steam_id={game.steam_id}></ActualCount>                    
                    <div className="col-sm-3"><a onClick={ () => this.goStats(game.steam_id) } href="#" className="btn btn-primary">Stats</a></div>
                </div>
            )
                
          });
        
        return (
            <div className="container-fluid">
                { elements }
            </div>
        );
      }
        
    }
}

export default Liste;