import React, { Component } from 'react';
import {LineChart,XAxis,YAxis,CartesianGrid,Line} from 'recharts';
import Waiting from './Waiting';
import moment from 'moment';

class GraphDaily extends Component {

    state = { 
        loaded:false,
        data: null,
        error:'',
    }    

    componentDidMount() {
        const {steam_id} = this.props
        let vUrl = "https://steam.kletellier.xyz/api/daily/" + steam_id;
        fetch(vUrl)
       .then(res => res.json())
       .then(
         (result) => {
           this.setState({
             loaded: true,
             data: result
           });
         },       
         (error) => {
           this.setState({
             loaded: true,
             error
           });
           console.log(error);
         }
       )
     }

    render() {        
        if(!this.state.loaded || this.state.data==null)
        {
            return (<Waiting taille="fa-2x"></Waiting>)
        }
        else
        { 
            const stats = this.state.data.stats;  
            if(stats.length==0)
            {
                return (<Waiting taille="fa-2x"></Waiting>)
            }
            else
            {
                let nb = (stats.length > 30) ? 30 : stats.length - 1; 
                if(nb<1){nb=1;}
            const statistics = [...this.state.data.stats].reverse().slice(0,nb).reverse();
            
            const stat2 = statistics.map( (elem) => {
                let date = moment(elem.jour,'YYYYMMDD').format('DD/MM');
                let count = elem.count;

                return {
                    date:elem.jour,
                    datem:date,
                    count:parseInt(count)
                }
                
            }); 
            let minVal = stat2.reduce( (min,p) => p.count<min ? p.count : min, stat2[0].count);
            let maxVal = stat2.reduce( (max,p) => p.count>max ? p.count : max, stat2[0].count);            
            let {largeur,hauteur,minimal} = this.props 
            if(largeur===undefined){largeur=300;}
            if(hauteur===undefined){hauteur=200;} 
            if(minimal===undefined){minimal=false;}
            if(!minimal)
            {
              return (
                <div className="col-12">
                    <LineChart width={largeur} height={hauteur} data={stat2}>
                        <XAxis dataKey="datem"/>
                        <YAxis type="number"/>
                        <CartesianGrid stroke="#eee" strokeDasharray="5 5"/>
                        <Line type="basis" dataKey="count" dot={false}  stroke="#0000ff" /> 
                    </LineChart>
                </div>
            );
            }
            else
            {
              return (
                <div className="rounded">
                    <LineChart width={largeur} height={hauteur} data={stat2}>
                        <Line type="basis" dataKey="count" dot={false} strokeWidth={1} stroke="#0000ff" /> 
                    </LineChart>
                </div>
            );
            }
            }
            
            
        }        
    }
}

export default GraphDaily;