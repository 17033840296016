import React, { Fragment } from 'react';

const Waiting = (props) => {
    const taille = (props.taille!==null) ? props.taille : "";
    const classe = `fas fa-spinner fa-spin ${taille}`
    return (
        <Fragment>
            <i className={classe}></i>
        </Fragment>
    );
};

export default Waiting;